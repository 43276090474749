import React from 'react'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { css } from '@emotion/react'
type Props = {
  progress: number
}

const LoadingProgress: React.FC<Props> = (props) => {
  return (
    <>
      <div css={loadingTextCss}>NOW OMOSHILOADING…</div>
      <div css={loadingCaptionCss}>面白いこと考え中です</div>
      <div css={progressCss}>
        <div css={progressBarCss}>
          <div
            css={progressBarBodyCss}
            style={{ width: `${props.progress * 100}%` }}
          ></div>
        </div>
        <div css={progressNumCss}>{Math.floor(props.progress * 100)}%</div>
      </div>
    </>
  )
}
export default LoadingProgress

const loadingTextCss = css`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`

const loadingCaptionCss = css`
  text-align: center;
  font-size: 12px;
`

const progressCss = css`
  margin-top: 8px;
`

const progressBarCss = css`
  border: 2px solid #fff;
  border-radius: 16px;
  height: 16px;
  overflow: hidden;
`

const progressBarBodyCss = css`
  background-color: #fff;
  width: 60%;
  height: 100%;
`

const progressNumCss = css`
  margin-top: 4px;
  color: #fff;
  text-align: center;
`
