import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import GlobalContext, { GlobalState } from '~/context/GlobalContext'
import VolumeWhiteOffIcon from '~/images/icon/volumeWhiteOff.svg'
import VolumeWhiteOnIcon from '~/images/icon/volumeWhiteOn.svg'
import VolumeBlackOffIcon from '~/images/icon/volumeBlackOff.svg'
import VolumeBlackOnIcon from '~/images/icon/volumeBlackOn.svg'
import AppGeneral from '~/libs/AppGeneral'
import AppSize from '~/libs/AppSize'

type Props = {
  isBgWhite?: boolean
}

const OpeningFooter: React.FC<Props> = (props) => {
  const toggleMute = (context: GlobalState) => {
    AppGeneral.Sound.mute(!context.mute)
    context.setMute(!context.mute)
  }

  const VolumeOnIcon = props.isBgWhite ? VolumeBlackOnIcon : VolumeWhiteOnIcon
  const VolumeOffIcon = props.isBgWhite
    ? VolumeBlackOffIcon
    : VolumeWhiteOffIcon

  return (
    <GlobalContext.Consumer>
      {(ctx) => {
        return (
          <div css={footerCss}>
            <img
              src={ctx.mute ? VolumeOffIcon : VolumeOnIcon}
              css={volumeCss}
              onClick={() => {
                toggleMute(ctx)
              }}
            />
            <Link to={'/mode-select'} className={'skipLink'}>
              SKIP
            </Link>
          </div>
        )
      }}
    </GlobalContext.Consumer>
  )
}
export default OpeningFooter

const footerCss = css`
  padding: 0 24px;
  height: 90px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  .skipLink {
    margin-left: auto;
    text-decoration: underline;
    font-size: 22px;
    letter-spacing: 0.12em;
  }
`

const volumeCss = css`
  width: 48px;
  height: 48px;
  @media (min-width: ${AppSize.sm}) {
    width: 55px;
    height: 55px;
  }
`
