import Img1 from '~/images/bgBoard.png'
import Img2 from '~/images/brainLargeBlue.png'
import Img3 from '~/images/brainLargeRed.png'
import Img4 from '~/images/opening/bgLandscape.png'
import Img5 from '~/images/opening/house.png'
import Img6 from '~/images/opening/houseBirdOut1.png'
import Img7 from '~/images/opening/houseBirdOut2.png'
import Img8 from '~/images/opening/flapSlow.png'
import Img9 from '~/images/opening/flapMedium.png'
import Img10 from '~/images/opening/flapFast.png'
import Img11 from '~/images/opening/logo.png'

import PcImg1 from '~/images/content/i1.png'
import PcImg2 from '~/images/content/i2.png'
import PcImg3 from '~/images/content/i3.png'
import PcImg4 from '~/images/content/i4.png'
import PcImg5 from '~/images/content/i5.png'
import PcImg6 from '~/images/content/i6.png'
import PcImg7_1 from '~/images/content/i7-1.png'
import PcImg7_2 from '~/images/content/i7-2.png'
import PcImg7_3 from '~/images/content/i7-3.png'
import PcImg8 from '~/images/content/i8.png'
import PcImg9 from '~/images/content/i9.png'
import PcImg10 from '~/images/content/i10.png'
import PcImg11 from '~/images/content/i11.png'
import PcImg12 from '~/images/content/i12.png'

import MbImg1_1 from '~/images/contentMb/i1-1.png'
import MbImg1_2 from '~/images/contentMb/i1-2.png'
import MbImg2 from '~/images/contentMb/i2.png'
import MbImg3 from '~/images/contentMb/i3.png'
import MbImg4 from '~/images/contentMb/i4.png'
import MbImg5_1 from '~/images/contentMb/i5-1.png'
import MbImg5_2 from '~/images/contentMb/i5-2.png'
import MbImg6_1 from '~/images/contentMb/i6-1.png'
import MbImg6_2 from '~/images/contentMb/i6-2.png'
import MbImg7_1 from '~/images/contentMb/i7-1.png'
import MbImg7_2 from '~/images/contentMb/i7-2.png'
import MbImg7_3 from '~/images/contentMb/i7-3.png'
import MbImg8 from '~/images/contentMb/i8.png'
import MbImg9 from '~/images/contentMb/i9.png'
import MbImg10 from '~/images/contentMb/i10.png'
import MbImg11 from '~/images/contentMb/i11.png'
import MbImg12 from '~/images/contentMb/i12.png'

import SecImg1 from '~/images/section/logoLargeMb.png'
import SecImg2 from '~/images/section/logoLargePc.png'
import SecImg3 from '~/images/section/profile1.png'
import SecImg4 from '~/images/section/profile2.png'
import SecImg5 from '~/images/section/profile3.png'
import SecImg6 from '~/images/section/profile4.png'

import MagImg1 from '~/images/section/magnet1.png'
import MagImg2 from '~/images/section/magnet2.png'
import MagImg3 from '~/images/section/magnet3.png'
import MagImg4 from '~/images/section/magnet4.png'
import MagImg5 from '~/images/section/magnet5.png'
import MagImg6 from '~/images/section/magnet6.png'
import MagImg7 from '~/images/section/magnet7.png'
import MagImg8 from '~/images/section/magnet8.png'
import MagImg9 from '~/images/section/magnet9.png'
import MagImg10 from '~/images/section/magnet10.png'
import MagImg11 from '~/images/section/magnet11.png'
import MagImg12 from '~/images/section/magnet12.png'
import MagImg13 from '~/images/section/magnet13.png'

import AppGeneral from '~/libs/AppGeneral'

export default class InitLoadManager {
  private isLoadedBgm = false
  private isLoadedFont = false
  private loadedImageCount = 0

  private imageSrcList = [
    Img1,
    Img2,
    Img3,
    Img4,
    Img5,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img11,
    PcImg1,
    PcImg2,
    PcImg3,
    PcImg4,
    PcImg5,
    PcImg6,
    PcImg7_1,
    PcImg7_2,
    PcImg7_3,
    PcImg8,
    PcImg9,
    PcImg10,
    PcImg11,
    PcImg12,
    MbImg1_1,
    MbImg1_2,
    MbImg2,
    MbImg3,
    MbImg4,
    MbImg5_1,
    MbImg5_2,
    MbImg6_1,
    MbImg6_2,
    MbImg7_1,
    MbImg7_2,
    MbImg7_3,
    MbImg8,
    MbImg9,
    MbImg10,
    MbImg11,
    MbImg12,
    SecImg1,
    SecImg2,
    SecImg3,
    SecImg4,
    SecImg5,
    SecImg6,
    MagImg1,
    MagImg2,
    MagImg3,
    MagImg4,
    MagImg5,
    MagImg6,
    MagImg7,
    MagImg8,
    MagImg9,
    MagImg10,
    MagImg11,
    MagImg12,
    MagImg13,
  ]
  private onProgressCallback = (progress: number) => {}
  private onCompleteCallback = () => {}

  public setOnProgress(onProgress: (progress: number) => void) {
    this.onProgressCallback = onProgress
  }
  public setOnComplete(onComplete: () => void) {
    this.onCompleteCallback = onComplete
  }

  public async start() {
    await this.loadFont()
    await this.loadBgm()
    await this.loadImages()
  }

  private loadFont() {
    return new Promise((resolve, reject) => {
      AppGeneral.loadFonts(() => {
        this.isLoadedFont = true
        this.onProgress()
        resolve(true)
      })
    })
  }
  private loadBgm() {
    return new Promise((resolve, reject) => {
      AppGeneral.Sound.loadBgm(() => {
        this.isLoadedBgm = true
        this.onProgress()
        resolve(true)
      })
    })
  }

  private async loadImages() {
    this.loadedImageCount = 0
    for (const src of this.imageSrcList) {
      await this.loadOneImage(src as string)
    }
  }

  private loadOneImage(src: string) {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        this.loadedImageCount++
        this.onProgress()
        resolve(true)
      }
      img.src = src
    })
  }

  private onProgress() {
    if (
      this.isLoadedBgm &&
      this.isLoadedFont &&
      this.loadedImageCount >= this.imageSrcList.length
    ) {
      this.onProgressCallback(1)
      this.onCompleteCallback()
    }
    let progressRate = 0
    if (this.isLoadedFont) {
      progressRate += 0.3
    }
    if (this.isLoadedBgm) {
      progressRate += 0.2
    }
    progressRate += (this.loadedImageCount / this.imageSrcList.length) * 0.5
    this.onProgressCallback(progressRate)
  }
}
